import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Container from "../../reusable/container"

import * as styles from "./footer.module.css"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "10logo@3x 1.png" }) {
        childImageSharp {
          fixed(width: 286, height: 82) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className={styles.footer}>
        <Link to="#">
          <Img
            fixed={data.image.childImageSharp.fixed}
            imgStyle={{
              objectFit: "contain",
            }}
            className={styles.logo}
          />
        </Link>
        <p>(c) 2023 Tapestry Inc. All rights reserved.</p>
      </div>
    </Container>
  )
}

export default Footer
