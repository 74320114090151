import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/homepage/hero"
import SignUpForm from "../components/homepage/signUpForm"
import Testimonials from "../components/homepage/testimonials"
import GradientBody from "../components/reusable/gradientBody"
import Footer from "../components/layout/footer"

const Homepage = () => (
  <Layout>
    <SEO title="Empowering all in conversations with visuals" />
    <Hero />
    <GradientBody>
      <SignUpForm />
      <Testimonials />
      <Footer />
    </GradientBody>
  </Layout>
)

export default Homepage
