import React from "react"

import Container from "../../reusable/container"

import * as styles from "./hero.module.css"

const Hero = () => {
  return (
    <div className={styles.hero}>
      <Container>
        <div className={styles.heroContent}>
          <h1>Our AI generates a Tapestry for every conversation</h1>
        </div>
      </Container>
    </div>
  )
}

export default Hero
