import React from "react"

import Container from "../../reusable/container"

import * as styles from "./signupform.module.css"

const SignUpForm = () => {
  return (
    <Container>
      <div className={styles.signupform}>
        <h3>Humanizing Online Meetings with Visual Facilitation</h3>
        <form onSubmit={e => e.preventDefault()}>
          <input type="text" placeholder="Sign up for Zoom App Beta" />
          <button type="submit">
            <h4>SOON</h4>
          </button>
        </form>
      </div>
    </Container>
  )
}

export default SignUpForm
