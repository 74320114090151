import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Container from "../../reusable/container"

import * as styles from "./testimonials.module.css"

const testimonials = [
  {
    message:
      "Tapestry helps people think differently. It<br/>helps with dry topics to give people a jolt…<br/><strong>You watch your ideas come to life.</strong>",
    author: "Chief Marketing Officer, Software company",
  },
  {
    message: "<strong>Tapestry is amazing.</strong>",
    author: "CEO, Futures think tank",
  },
  {
    message:
      "<strong>Tapestry is an anchoring heuristic.</strong> It brings the emotional aspects that are missing in online meetings.",
    author:
      "Senior Behaviorial Health Medical Director, mid-states healthcare group",
  },
]

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      spacer: file(relativePath: { eq: "Tapestry spacer bar@3x 1.png" }) {
        childImageSharp {
          fixed(width: 182, height: 12) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Container>
      <div className={styles.testimonials}>
        <Img fixed={data.spacer.childImageSharp.fixed} />
        {testimonials.map(testimonial => (
          <div className={styles.testimonial}>
            <h4
              className={styles.message}
              dangerouslySetInnerHTML={{ __html: testimonial.message }}
            ></h4>
            <h5 className={styles.author}>{testimonial.author}</h5>
            <Img fixed={data.spacer.childImageSharp.fixed} />
          </div>
        ))}
      </div>
    </Container>
  )
}

export default Testimonials
